import * as React from "react"
import { useEffect } from 'react';
import jQuery from 'jquery';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import '../css/style.css';
import '../css/responsive.css';

import logoLight from '../img/asians-group-light.png';
import logoDark from '../img/asians-group-dark.png';
import homeBottomShape from '../img/home-bottom-shape.png';

function initLanguageSwitcher() {
    const pathname = window.location.pathname;
    // Filter to remove empty strings.
    const pathSegments = pathname.trim().split('/').filter((s) => Boolean(s));

    let lang = 'en';
    if (pathSegments.length > 1) {
        lang = pathSegments[0];
    }

    jQuery(`#language-switcher option[value=${lang}]`).attr('selected', true);

    jQuery("#language-switcher").change(function (x) {
        const toLang = jQuery("#language-switcher option:selected").val();
        if (toLang === 'en') {
            window.location = '/affiliate-program';
            return;
        }

        window.location = `/${toLang}/affiliate-program`;
    });
}

// markup
const IndexPage = () => {
    const { t } = useTranslation();

    // on component mount
    useEffect(() => {
        initLanguageSwitcher();

        // trigger handleScroll on initial render
        handleScroll();

        window.addEventListener('scroll', handleScroll);
    }, []);

    function handleScroll() {
        var pixels = 50;
        var top = 1200;
        if (jQuery(window).scrollTop() > pixels) {
            jQuery('.navbar-expand-md').addClass('navbar-reduce');
            jQuery('.navbar-expand-md').removeClass('navbar-trans');
        } else {
            jQuery('.navbar-expand-md').addClass('navbar-trans');
            jQuery('.navbar-expand-md').removeClass('navbar-reduce');
        }
        if (jQuery(window).scrollTop() > top) {
            jQuery('.scrolltop-mf').fadeIn(1000, "easeInOutExpo");
        } else {
            jQuery('.scrolltop-mf').fadeOut(1000, "easeInOutExpo");
        }
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{t('Asian Cloud CDN, high speed, high defense, high stability!')}</title>
                <link rel="canonical" href="https://asians.group" />
                <meta name="keywords"
                    content="domains, registration, best cdn, cdn, service, free, node, ssl, security, mitigation" />
                <meta name="description"
                    content="Asian Cloud CDN provides one-stop solution to CDN acceleration and defense, DNS service, certificate service. Making website management extremely simple." />
            </Helmet>

            <div className="page-wrapper">
                <nav className="navbar navbar-b navbar-trans navbar-expand-md fixed-top" id="mainNav">
                    <div className="container align-items-baseline">
                        <a className="navbar-brand js-scroll" href="/">
                            <img className="navbar-brand-regular" src={logoLight} width="190"
                                alt="brand-logo-white" />
                            <img className="navbar-brand-dark" src={logoDark} width="190"
                                alt="brand-logo-dark" />
                        </a>

                        <select id="language-switcher">
                            <option value="en">en</option>
                            <option value="ko">ko</option>
                            <option value="zh">zh</option>
                        </select>

                        <div className="navbar-collapse collapse justify-content-end" id="navbarDefault">
                            <ul className="navbar-nav">
                                <li className="nav-item"><a className="nav-link js-scroll active"
                                    href="/#home"><Trans>Home</Trans></a>
                                </li>
                                <li className="nav-item"><a className="nav-link js-scroll"
                                    href="/#security"><Trans>Security</Trans></a>
                                </li>
                                <li className="nav-item"><a className="nav-link js-scroll" href="#our-tech"><Trans>Our
                                    Tech</Trans></a>
                                </li>
                                <li className="nav-item"><a className="nav-link js-scroll"
                                    href="#services"><Trans>Services</Trans></a>
                                </li>
                                <li className="nav-item"><a className="nav-link js-scroll"
                                    href="#price"><Trans>Pricing</Trans></a></li>
                                <li id="userprofile" className="nav-item top-button" style={{ display: 'none' }}>
                                    <a href="https://console.asians.group/" id="username"
                                        className="nav-link js-scroll"><Trans>Console</Trans></a>
                                </li>
                                <li id="loginpanel" className="nav-item top-button">
                                    <a href="https://console.asians.group/" className="nav-link js-scroll">
                                        <Trans>Login</Trans>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>

                <header id="home"
                    className="home-area d-flex align-items-center">
                    <div className="container">
                        <div className="row d-flex align-items-center">
                            <div className="col-12 col-md-6 col-lg-6">
                                <div className="text-left home-content z-index position-relative">
                                    <h1><Trans>Asians Group CDN Affiliate Program</Trans></h1>
                                    <p>
                                        <Trans>AFFILIATE_PROGRAM_INTRO</Trans>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="svg-shape-bottom">
                        <img src={homeBottomShape} className="bottom-shape img-fluid" alt="" />
                    </div>
                </header>

                <section className="p-5">
                    <div className="container">
                        <div className="section-title">
                            <h2><Trans>How Our Affiliate Program Operates</Trans></h2>
                        </div>
                        <div>
                            <p className="lead mb-4">{t('HOW_AFFILIATE_PROGRAM_WORK_1')}</p>
                            <p className="lead mb-4" dangerouslySetInnerHTML={{ __html: t('HOW_AFFILIATE_PROGRAM_WORK_2') }}></p>
                            <p className="lead mb-4" dangerouslySetInnerHTML={{ __html: t('HOW_AFFILIATE_PROGRAM_WORK_3') }}></p>
                        </div>
                    </div>
                </section>

                <section className="p-5 mt-5">
                    <div className="container">
                        <div className="section-title mb-4">
                            <h2>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="mr-2"><path d="M11 15h2a2 2 0 1 0 0-4h-3c-.6 0-1.1.2-1.4.6L3 17" /><path d="m7 21 1.6-1.4c.3-.4.8-.6 1.4-.6h4c1.1 0 2.1-.4 2.8-1.2l4.6-4.4a2 2 0 0 0-2.75-2.91l-4.2 3.9" /><path d="m2 16 6 6" /><circle cx="16" cy="9" r="2.9" /><circle cx="6" cy="5" r="3" /></svg>
                                <Trans>Earn</Trans>
                            </h2>
                        </div>
                        <div>
                            <p className="lead mb-4">{t('EARN_DESCRIPTION')}</p>
                        </div>
                    </div>
                </section>

                <section className="m-5">
                    <div className="container">
                        <div className="d-flex flex-column flex-lg-row justify-content-between">
                            <div className="px-2 mb-5 mr-3">
                                <h4 className="section-title mb-4 font-weight-bold">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="mr-2"><line x1="19" x2="5" y1="5" y2="19" /><circle cx="6.5" cy="6.5" r="2.5" /><circle cx="17.5" cy="17.5" r="2.5" /></svg>
                                    <Trans>Commission Structure</Trans>
                                </h4>
                                <p className="lead mb-2 text-justify" dangerouslySetInnerHTML={{ __html: t('COMMISSION_STRUCTURE_DESCRIPTION') }}></p>
                            </div>
                            <div className="px-2 ml-2">
                                <h4 className="section-title mb-4 font-weight-bold">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                         fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                         stroke-linejoin="round" className="mr-2">
                                        <polygon
                                            points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"/>
                                    </svg>
                                    <Trans>How to Get Started</Trans>
                                </h4>
                                <p className="lead text-justify mb-3">{t('HOW_GET_STARTED_DESCRIPTION')}</p>
                                <ul className="ml-3 mt-2">
                                    <li className="mt-2 mb-3">
                                        <p className="lead text-justify">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-chevron-right"><path d="m9 18 6-6-6-6" /></svg>
                                            <span dangerouslySetInnerHTML={{ __html: t('HOW_TO_GET_STARTED_STEP_1') }}></span>
                                        </p>
                                    </li>
                                    <li className="mb-3">
                                        <p className="lead text-justify">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-chevron-right"><path d="m9 18 6-6-6-6" /></svg>

                                            <span dangerouslySetInnerHTML={{ __html: t('HOW_TO_GET_STARTED_STEP_2') }}></span>
                                        </p>
                                    </li>
                                    <li className="mb-3">
                                        <p className="lead text-justify">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-chevron-right"><path d="m9 18 6-6-6-6" /></svg>
                                            <span dangerouslySetInnerHTML={{ __html: t('HOW_TO_GET_STARTED_STEP_3') }}></span>
                                        </p>
                                    </li>
                                    <li className="mb-3">
                                        <p className="lead text-justify">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-chevron-right"><path d="m9 18 6-6-6-6" /></svg>
                                            <span dangerouslySetInnerHTML={{ __html: t('HOW_TO_GET_STARTED_STEP_4') }}></span>
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

setTimeout(function () {
    const toLang = jQuery("#language-switcher option:selected").val();
    if (toLang == "zh") {
        jQuery("#asian_document").attr("href", "https://docs.asians.group/cdn-yong-hu-shou-ce/");
    } else {
        jQuery("#asian_document").attr("href", "https://docs.asians.group/");
    }
}, 1000);